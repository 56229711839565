import React from 'react';
import _ from 'lodash';
import Markdown from 'react-markdown';
import { Link } from 'gatsby';
//
import './ArticleQuoteBlock.scss';

export default function ArticleQuoteBlock({ fields }) {
  const parseFields = () => {
    return {
      hasTopBorder: _.get(fields, 'hasTopBorder', false),
      quoteText: _.get(fields, 'quoteText.fields.text', 'QUOTE TEXT MISSING'),
      quoteTextSize: _.get(fields, 'quoteText.fields.textSize', 'Medium'),
      quoteAttribution: _.get(
        fields,
        'quoteAttribution.fields.text',
        'QUOTE TEXT MISSING'
      ),
      quoteAttributionTextSize: _.get(
        fields,
        'quoteAttribution.fields.textSize',
        'Medium'
      ),
      articleSourceText: _.get(
        fields,
        'articleSourceText.fields.text',
        'ARTICLE SOURCE TEXT MISSING'
      ),
      articleSourceTextSize: _.get(
        fields,
        'articleSourceText.fields.textSize',
        'Medium'
      ),
      articleTitleText: _.get(
        fields,
        'articleTitleText.fields.text',
        'ARTICLE TITLE TEXT MISSING'
      ),
      articleTitleTextSize: _.get(
        fields,
        'articleTitleText.fields.textSize',
        'Medium'
      ),
      button: _.get(fields, 'button.fields', 'BUTTON MISSING'),
    };
  };

  const {
    hasTopBorder,
    quoteText,
    quoteTextSize,
    quoteAttribution,
    quoteAttributionTextSize,
    articleSourceText,
    articleSourceTextSize,
    articleTitleText,
    articleTitleTextSize,
    button,
  } = parseFields();

  return (
    <div
      className={`ArticleQuoteBlock ${hasTopBorder ? 'has-top-border' : ''}`}
    >
      <div className='quotation-mark'>“</div>
      <div className={`quote-text text-${quoteTextSize}`}>
        <Markdown children={quoteText} />
      </div>
      <div
        className={`quote-attribution-text text-${quoteAttributionTextSize}`}
      >
        <Markdown children={quoteAttribution} />
      </div>
      <div className={`source-text text-${articleSourceTextSize}`}>
        <Markdown children={articleSourceText} />
      </div>
      <div className={`title-text text-${articleTitleTextSize}`}>
        <Markdown children={articleTitleText} />
      </div>
      {button.opensNewWindow ? (
        <a
          className='button'
          href={button.link}
          target='_blank'
          rel='noopener noreferrer'
        >
          {button.labelText}
        </a>
      ) : (
        <Link className='button' to={button.link}>
          {button.labelText}
        </Link>
      )}
    </div>
  );
}
