import React from 'react';
import _ from 'lodash';
import Markdown from 'react-markdown';
import { Link } from 'gatsby';
//
import './Tout.scss';

export default function Tout({ fields }) {
  const parseFields = () => {
    return {
      headerText: _.get(
        fields,
        'headerText.fields.text',
        'HEADER TEXT MISSING'
      ),
      headerTextSize: _.get(fields, 'headerText.fields.textSize', 'Medium'),
      subheadText: _.get(
        fields,
        'subheadText.fields.text',
        'SUBHEAD TEXT MISSING'
      ),
      subheadTextSize: _.get(fields, 'subheadText.fields.textSize', 'Medium'),
      mobileImage: _.get(
        fields,
        'mobileImage.fields.image.fields.file.url',
        '/img/PLACEHOLDER.jpg'
      ),
      desktopImage: _.get(
        fields,
        'desktopImage.fields.image.fields.file.url',
        '/img/PLACEHOLDER.jpg'
      ),
      altText: _.get(fields, 'desktopImage.fields.altText', 'ALT TEXT MISSING'),
      button: _.get(fields, 'button.fields', 'BUTTON MISSING'),
    };
  };

  const {
    headerText,
    headerTextSize,
    subheadText,
    subheadTextSize,
    mobileImage,
    desktopImage,
    altText,
    button,
  } = parseFields();

  return (
    <div className='Tout'>
      <div className='image-wrapper'>
        <picture>
          <source
            media='(max-width: 767px)'
            srcSet={`${mobileImage}`}
            sizes='100vw'
          />
          <img src={desktopImage} alt={altText} />
        </picture>
      </div>
      <div className='text-wrapper'>
        <Markdown
          className={`header-text text-${headerTextSize}`}
          children={headerText}
        />
        <Markdown
          className={`subhead-text text-${subheadTextSize}`}
          children={subheadText}
        />
        {button.opensNewWindow ? (
          <a
            className='button'
            href={button.link}
            target='_blank'
            rel='noopener noreferrer'
          >
            {button.labelText}
          </a>
        ) : (
          <Link className='button' to={button.link}>
            {button.labelText}
          </Link>
        )}
      </div>
    </div>
  );
}
