import React, { useContext } from 'react';
import _ from 'lodash';
import Markdown from 'react-markdown';
//
import './StoryBlock.scss';
import { STORE_CONSTANTS, Store } from './../../Store';

export default function ArticleBlock({ fields }) {
  const parseFields = () => {
    return {
      quoteText: _.get(
        fields,
        'quoteText.fields.text',
        'ARTICLE SOURCE TEXT MISSING'
      ),
      quoteTextSize: _.get(fields, 'quoteText.fields.textSize', 'Medium'),
      quoteAttributionText: _.get(
        fields,
        'quoteAttributionText.fields.text',
        'ARTICLE SOURCE TEXT MISSING'
      ),
      quoteAttributionTextSize: _.get(
        fields,
        'quoteAttributionText.fields.textSize',
        'Medium'
      ),
      quoteImageMobile: _.get(
        fields,
        'quoteImageMobile.fields.image.fields.file.url',
        '/img/PLACEHOLDER.jpg'
      ),
      quoteImageDesktop: _.get(
        fields,
        'quoteImageDesktop.fields.image.fields.file.url',
        '/img/PLACEHOLDER.jpg'
      ),
      youtubeVideoID: _.get(
        fields,
        'youtubeVideo.fields.videoID',
        'VIDEO ID MISSING'
      ),
      videoPreviewImageMobile: _.get(
        fields,
        'videoPreviewImageMobile.fields.image.fields.file.url',
        '/img/PLACEHOLDER.jpg'
      ),
      videoPreviewImageDesktop: _.get(
        fields,
        'videoPreviewImageDesktop.fields.image.fields.file.url',
        '/img/PLACEHOLDER.jpg'
      ),
      altText: _.get(
        fields,
        'quoteImageMobile.fields.altText',
        'ALT TEXT MISSING'
      ),
      hasBottomnPadding: _.get(fields, 'hasBottomPadding', true),
      alignRight: _.get(fields, 'alignRight', false),
    };
  };

  const {
    quoteText,
    quoteTextSize,
    quoteAttributionText,
    quoteAttributionTextSize,
    quoteImageMobile,
    quoteImageDesktop,
    youtubeVideoID,
    videoPreviewImageMobile,
    videoPreviewImageDesktop,
    altText,
    hasBottomnPadding,
    alignRight,
  } = parseFields();

  const { dispatch } = useContext(Store);

  const handleClickOpenOverlay = () => {
    dispatch({
      type: STORE_CONSTANTS.ACTIONS.SET_CURRENT_YOUTUBE_OVERLAY_VIDEO_ID,
      payload: youtubeVideoID,
    });

    dispatch({
      type: STORE_CONSTANTS.ACTIONS.SET_DISPLAY_YOUTUBE_OVERLAY,
      payload: true,
    });
  };

  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      handleClickOpenOverlay();
    }
  };

  return (
    <div
      className={`StoryBlock ${hasBottomnPadding ? 'has-bottom-padding' : ''}`}
    >
      <div className={`quote-wrapper ${alignRight ? 'align-right' : ''}`}>
        <div className='quote-image'>
          <picture>
            <source
              media='(max-width: 767px)'
              srcSet={`${quoteImageMobile}`}
              sizes='100vw'
            />
            <img src={quoteImageDesktop} alt={altText} />
          </picture>
        </div>
        <div className='quote-text-wrapper'>
          <div className={`quote-text text-${quoteTextSize}`}>
            <Markdown children={quoteText} />
          </div>
          <div
            className={`quote-attribution=text text-${quoteAttributionTextSize}`}
          >
            <Markdown children={quoteAttributionText} />
          </div>
        </div>
      </div>

      {youtubeVideoID != 'VIDEO ID MISSING' ? (
        <div
          className='video-wrapper'
          onClick={handleClickOpenOverlay}
          onKeyDown={e => handleKeyDown(e)}
          role='button'
          tabIndex={0}
        >
          <picture>
            <source
              media='(max-width: 767px)'
              srcSet={`${videoPreviewImageMobile}`}
              sizes='100vw'
            />
            <img src={videoPreviewImageDesktop} alt={altText} />
          </picture>
          <div className='play-button'>
            <img src='/img/play.svg' alt='Play' />
          </div>
        </div>
      ) : null}
    </div>
  );
}
