import React from 'react';
import _ from 'lodash';
import MissionStatement from './MissionStatement';
import AlumniMembers from './AlumniMembers';
import AlumniCommunity from './AlumniCommunity';

export default function AlumniBlock({ fields }) {
  const {
    alumniBoardMembers,
    alumniBoardMembersHeadline,
    alumniBoardMembersTitles,
    alumniMissionStatement,
    alumniCommunityTitle,
    alumniCommunityImages,
    alumniCommunityImagesCaptions,
  } = fields;

  return (
    <>
      <MissionStatement {...alumniMissionStatement} />
      <AlumniMembers
        media={alumniBoardMembers}
        captions={alumniBoardMembersTitles}
        title={alumniBoardMembersHeadline}
      />
      <AlumniCommunity
        media={alumniCommunityImages}
        captions={alumniCommunityImagesCaptions}
        title={alumniCommunityTitle}
      />
    </>
  );
}
