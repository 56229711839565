import React from 'react';
import _ from 'lodash';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

export default function MissionStatement({ fields }) {
  const parseMission = () => {
    return {
      headerText: _.get(
        fields,
        'headerText.fields.text',
        'HEADER TEXT MISSING'
      ),
      headerTextSize: _.get(fields, 'headerText.fields.textSize', 'Large2'),
      subheadText: _.get(
        fields,
        'subheadText.fields.text',
        'SUBHEAD TEXT MISSING'
      ),
      subheadTextSize: _.get(fields, 'subheadText.fields.textSize', 'Large2'),
    };
  };

  const { headerText, headerTextSize, subheadText, subheadTextSize } =
    parseMission();

  return (
    <section className={`mission-statement`}>
      <div className={`TextBlock`}>
        <div className={`header-text text-${headerTextSize}`}>
          <Markdown children={headerText} rehypePlugins={[rehypeRaw]} />
        </div>
        {subheadText ? (
          <div className={`subhead-text text-${subheadTextSize}`}>
            <Markdown children={subheadText} />
          </div>
        ) : null}
      </div>
    </section>
  );
}
