import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Markdown from 'react-markdown';
//
import './CompanyList.scss';

export default function CompanyList({ fields }) {
  const [isMobile, setisMobile] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth > 768) {
      setisMobile(false);
    } else {
      setisMobile(true);
    }
  }, []);

  const parseFields = () => {
    return {
      headerText: _.get(
        fields,
        'headerText.fields.text',
        'HEADER TEXT MISSING'
      ),
      headerTextSize: _.get(fields, 'headerText.fields.textSize', 'Medium'),
      subheadText: _.get(
        fields,
        'subheadText.fields.text',
        'SUBHEAD TEXT MISSING'
      ),
      subheadTextSize: _.get(fields, 'subheadText.fields.textSize', 'Medium'),
      companies: _.get(fields, 'companies', []),
    };
  };

  const parseImage = logoImage => {
    return {
      companyLogo: _.get(
        logoImage,
        'fields.image.fields.file.url',
        '/img/PLACEHOLDER.jpg'
      ),
      companyLogoAltText: _.get(
        logoImage,
        'fields.altText',
        'ALT TEXT MISSING'
      ),
    };
  };

  const renderCompanyLinks = textLinks => {
    return textLinks.map((textLink, index) => {
      const { labelText, link, opensNewWindow } = textLink.fields;

      return (
        <a
          key={index}
          className='text-XSmall'
          href={link}
          target={opensNewWindow ? '_blank' : '_self'}
          rel={opensNewWindow ? 'noopener noreferrer' : ''}
        >
          {labelText}
        </a>
      );
    });
  };

  const getLogoStyle = (desktopLogoImageWidth, mobileLogoImageWidth) => {
    const style = {};

    if (isMobile) {
      style.width = `${mobileLogoImageWidth}px`;
    } else {
      style.width = `${desktopLogoImageWidth}px`;
    }

    return style;
  };

  const renderCompanies = () => {
    return companies.map((company, index) => {
      const {
        descriptionText,
        logoImage,
        desktopLogoImageWidth,
        mobileLogoImageWidth,
        textLinks,
      } = company.fields;

      const { companyLogo, companyLogoAltText } = parseImage(logoImage);

      return (
        <div key={index} className='company'>
          <div className='company-logo'>
            <img
              src={companyLogo}
              alt={companyLogoAltText}
              style={getLogoStyle(desktopLogoImageWidth, mobileLogoImageWidth)}
            />
          </div>
          <div
            className={`company-description text-${descriptionText.fields.textSize}`}
          >
            {descriptionText.fields.text}
          </div>
          <div className='company-links'>{renderCompanyLinks(textLinks)}</div>
        </div>
      );
    });
  };

  const {
    headerText,
    headerTextSize,
    subheadText,
    subheadTextSize,
    companies,
  } = parseFields();

  return (
    <div className='CompanyList'>
      <div className='header'>
        <div className={`header-text text-${headerTextSize}`}>
          <Markdown children={headerText} />
        </div>
        <div className={`subhead-text text-${subheadTextSize}`}>
          <Markdown children={subheadText} />
        </div>
      </div>
      <div className='companies'>{renderCompanies()}</div>
    </div>
  );
}
