import React from 'react';
import _ from 'lodash';
import Markdown from 'react-markdown';
//
import './FAQ.scss';

export default function FAQ({ fields }) {
  const parseFields = () => {
    return {
      questionText: _.get(
        fields,
        'questionText.fields.text',
        'QUESTION TEXT MISSING'
      ),
      questionTextSize: _.get(fields, 'questionText.fields.textSize', 'Medium'),
      answerText: _.get(
        fields,
        'answerText.fields.text',
        'ANSWER TEXT MISSING'
      ),
      answerTextSize: _.get(fields, 'answerText.fields.textSize', 'Medium'),
      steps: _.get(fields, 'steps', []),
    };
  };

  const { questionText, questionTextSize, answerText, answerTextSize, steps } =
    parseFields();

  return (
    <div className='FAQ'>
      <div className={`question-text text-${questionTextSize}`}>
        <Markdown children={questionText} />
      </div>
      <div className={`answer-text text-${answerTextSize}`}>
        <Markdown children={answerText} />
      </div>
      {steps.length > 0 ? (
        <div className='steps'>
          {steps.map((step, i) => {
            const headerText = step.fields.headerText.fields.text;
            const headerTextSize = step.fields.headerText.fields.textSize;
            const subheadText = step.fields.subheadText.fields.text;
            const subheadTextSize = step.fields.subheadText.fields.textSize;

            return (
              <div key={i} className='step'>
                <div className={`header-text text-${headerTextSize}`}>
                  <Markdown children={headerText} />
                </div>
                <div className={`subhead-text text-${subheadTextSize}`}>
                  <Markdown children={subheadText} />
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
