import React from 'react';
import _ from 'lodash';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import './AlumniMembers.scss';

export default function AlumniMembers({ media, captions, title }) {
  const parseHeadline = () => {
    const fields = title.fields;
    return {
      headerText: _.get(fields, 'text', 'HEADER TEXT MISSING'),
      textSize: _.get(fields, 'textSize', 'Large2'),
    };
  };

  const renderHeadline = () => {
    const { headerText, textSize } = parseHeadline();

    return (
      <div className={`TextBlock reduce-vertical-padding`}>
        <div className={`header-text text-${textSize}`}>
          <Markdown children={headerText} rehypePlugins={[rehypeRaw]} />
        </div>
      </div>
    );
  };

  const parseImage = image => {
    return {
      src: _.get(image, 'fields.image.fields.file.url', '/img/PLACEHOLDER.jpg'),
      alt: _.get(image, 'fields.altText', 'ALT TEXT MISSING'),
    };
  };

  const parseImageCaption = caption => {
    return {
      headerText: _.get(caption, 'fields.headerText.fields.text', ''),
      headerTextSize: _.get(caption, 'fields.headerText.fields.textSize', ''),
      subheadText: _.get(caption, 'fields.subheadText.fields.text', ''),
      subheadTextSize: _.get(caption, 'fields.subheadText.fields.textSize', ''),
    };
  };

  const renderMembers = () => {
    return media.map((img, index) => {
      const { src, alt } = parseImage(img);
      let capt = captions[index] || {};

      let { headerText, headerTextSize, subheadText, subheadTextSize } =
        parseImageCaption(capt);

      return (
        <div key={index} className='members-item'>
          <div className='members-item--media'>
            <img src={src} alt={alt} />
          </div>
          <div className={`members-item--caption`}>
            {headerText ? (
              <div className={`header-text text-${headerTextSize}`}>
                <Markdown children={headerText} />
              </div>
            ) : null}

            {subheadText ? (
              <div className={`subhead-text text-Xsmall`}>
                <Markdown children={subheadText} />
              </div>
            ) : null}
          </div>
        </div>
      );
    });
  };

  return (
    <section className={`AlumniMembers`}>
      <div className='headline-text'>{renderHeadline()}</div>
      <div className='members'>{renderMembers()}</div>
    </section>
  );
}
