import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import Glide from '@glidejs/glide';
import '@glidejs/glide/src/assets/sass/glide.core.scss';
//
import './SocialMediaCarousel.scss';

export default function SocialMediaCarousel({ fields }) {
  useEffect(() => {
    if (typeof window !== 'undefined') init();
  }, []);

  const glideRef = useRef();
  const glideContainerRef = useRef();

  const [currentPage, setCurrentPage] = useState(1);

  const init = () => {
    const options = {
      type: 'carousel',
      gap: 0,
      breakpoints: {
        767: {
          perView: 1,
        },
        3840: {
          perView: 2,
        },
      },
    };
    glideRef.current = new Glide(glideContainerRef.current, options);
    glideRef.current.on('run', () =>
      setCurrentPage(glideRef.current.index + 1)
    );
    glideRef.current.mount();
  };

  const parseFields = () => {
    return {
      headerText: _.get(
        fields,
        'headerText.fields.text',
        'HEADER TEXT MISSING'
      ),
      headerTextSize: _.get(fields, 'headerText.fields.textSize', 'Medium'),
      carouselItems: _.get(fields, 'carouselItems', []),
    };
  };

  const { headerText, headerTextSize, carouselItems } = parseFields();

  const renderRetweetedTweet = retweetedTweet => {
    const {
      displayNameText,
      dateText,
      tweetText,
      // twitterLink,
    } = retweetedTweet.fields;

    return (
      // <a
      //   className='retweeted-tweet'
      //   href={twitterLink}
      //   target='_blank'
      //   rel='noopener noreferrer'
      // >
      <div className='retweeted-tweet'>
        <div className='retweet-header'>
          <span
            className={`retweet-name text-${displayNameText.fields.textSize}`}
          >
            {displayNameText.fields.text}
          </span>{' '}
          |{' '}
          <span className={`retweet-date text-${dateText.fields.textSize}`}>
            {dateText.fields.text}
          </span>
        </div>
        <div className={`retweet-text text-${tweetText.fields.textSize}`}>
          {tweetText.fields.text}
        </div>
      </div>
      // </a>
    );
  };

  const renderSlides = () => {
    return carouselItems.map((item, index) => {
      const {
        displayNameText,
        dateText,
        twitterLink,
        socialMediaSource,
        tweetText,
        image,
        retweetedTweet,
      } = item.fields;

      return (
        <li key={index} className='glide__slide carousel-item'>
          <div className='social-logo'>
            <img
              src={`/img/${socialMediaSource}-logo.png`}
              alt={`${socialMediaSource} logo`}
            />
          </div>
          <div
            className={`display-name text-${displayNameText.fields.textSize}`}
          >
            <a href={twitterLink} target='_blank'>
              {displayNameText.fields.text}
            </a>
          </div>
          <div className={`date-text text-${dateText.fields.textSize}`}>
            {dateText.fields.text}
          </div>
          <div className={`tweet-text text-${tweetText.fields.textSize}`}>
            {tweetText.fields.text}
          </div>
          {image && (
            <div className='tweet-image'>
              <a href={twitterLink} target='_blank'>
                <img
                  src={image.fields.image.fields.file.url}
                  alt={image.fields.altText}
                />
              </a>
            </div>
          )}
          {retweetedTweet && renderRetweetedTweet(retweetedTweet)}
        </li>
      );
    });
  };

  const handlePaginate = dir => {
    if (dir === 'right') {
      glideRef.current.go('>');
    } else {
      glideRef.current.go('<');
    }

    setCurrentPage(glideRef.current.index + 1);
  };

  const handleKeyDown = (event, direction) => {
    if (event.keyCode === 13) {
      handlePaginate(direction);
    }
  };

  return (
    <div className='SocialMediaCarousel'>
      <div className='header'>
        <div className={`header-text text-${headerTextSize}`}>{headerText}</div>
      </div>
      <div className='carousel-container glide' ref={glideContainerRef}>
        <div className='glide__track' data-glide-el='track'>
          <ul className='glide__slides'>{renderSlides()}</ul>
        </div>
      </div>
      <div className='pagination text-Medium'>
        <div
          className='paginate paginate-left'
          onClick={() => handlePaginate('left')}
          onKeyDown={e => handleKeyDown(e, 'left')}
          role='button'
          tabIndex='0'
        >
          <img src='/img/right-caret_blk.png' alt='Paginate left' />
        </div>
        <div className='page-count'>
          <span className='current-page'>{currentPage}</span> /{' '}
          <span className='total-pages'>{carouselItems.length}</span>
        </div>
        <div
          className='paginate paginate-right'
          onClick={() => handlePaginate('right')}
          onKeyDown={e => handleKeyDown(e, 'right')}
          role='button'
          tabIndex='0'
        >
          <img src='/img/right-caret_blk.png' alt='Paginate right' />
        </div>
      </div>
    </div>
  );
}
