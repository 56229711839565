import React from 'react';
import _ from 'lodash';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Link } from 'gatsby';
//
import './TextBlock.scss';

export default function TextBlock({ fields }) {
  const parseFields = () => {
    return {
      headerText: _.get(
        fields,
        'headerText.fields.text',
        'HEADER TEXT MISSING'
      ),
      headerTextSize: _.get(fields, 'headerText.fields.textSize', 'Medium'),
      headerTextColor: _.get(
        fields,
        'headerText.fields.textColor.fields.hexColor',
        null
      ),
      subheadText: _.get(fields, 'subheadText.fields.text', null),
      subheadTextSize: _.get(fields, 'subheadText.fields.textSize', 'Medium'),
      button: _.get(fields, 'button.fields', null),
      reduceVerticalPadding: _.get(fields, 'reduceVerticalPadding', false),
    };
  };

  const {
    headerText,
    headerTextSize,
    headerTextColor,
    subheadText,
    subheadTextSize,
    button,
    reduceVerticalPadding,
  } = parseFields();

  const headerStyle = {};

  if (headerTextColor) headerStyle.color = headerTextColor;

  return (
    <div
      className={`TextBlock ${
        reduceVerticalPadding ? 'reduce-vertical-padding' : ''
      }`}
    >
      <div className={`header-text text-${headerTextSize}`} style={headerStyle}>
        <Markdown children={headerText} rehypePlugins={[rehypeRaw]} />
      </div>
      {subheadText ? (
        <div className={`subhead-text text-${subheadTextSize}`}>
          <Markdown children={subheadText} />
        </div>
      ) : null}
      {button ? (
        button.opensNewWindow ? (
          <a
            className={`button ${button.isLargerSize ? 'larger-size' : ''}`}
            href={button.link}
            target='_blank'
            rel='noopener noreferrer'
          >
            {button.labelText}
          </a>
        ) : (
          <Link className='button' to={button.link}>
            {button.labelText}
          </Link>
        )
      ) : null}
    </div>
  );
}
