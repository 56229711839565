import React from 'react';
import _ from 'lodash';
import Markdown from 'react-markdown';
import { Link } from 'gatsby';
//
import './ArticleBlock.scss';

export default function ArticleBlock({ fields }) {
  const parseFields = () => {
    return {
      articleSourceText: _.get(
        fields,
        'articleSourceText.fields.text',
        'ARTICLE SOURCE TEXT MISSING'
      ),
      articleSourceTextSize: _.get(
        fields,
        'articleSourceText.fields.textSize',
        'Medium'
      ),
      articleTitleText: _.get(
        fields,
        'articleTitleText.fields.text',
        'ARTICLE TITLE TEXT MISSING'
      ),
      articleTitleTextSize: _.get(
        fields,
        'articleTitleText.fields.textSize',
        'Medium'
      ),
      articleImage: _.get(
        fields,
        'articleImage.fields.image.fields.file.url',
        '/img/PLACEHOLDER.jpg'
      ),
      altText: _.get(fields, 'articleImage.fields.altText', 'ALT TEXT MISSING'),
      articleImageDisplaySide: _.get(fields, 'articleImageDisplaySide', 'Left'),
      button: _.get(fields, 'button.fields', 'BUTTON MISSING'),
    };
  };

  const {
    articleSourceText,
    articleSourceTextSize,
    articleTitleText,
    articleTitleTextSize,
    articleImage,
    altText,
    articleImageDisplaySide,
    button,
  } = parseFields();

  return (
    <div
      className={`ArticleBlock ${
        articleImageDisplaySide === 'Right' ? 'reverse' : ''
      }`}
    >
      <div className='article-image'>
        <img src={articleImage} alt={altText} />
      </div>
      <div className='article-copy'>
        <div className={`source-text text-${articleSourceTextSize}`}>
          <Markdown children={articleSourceText} />
        </div>
        <div className={`title-text text-${articleTitleTextSize}`}>
          <Markdown children={articleTitleText} />
        </div>
        {button.opensNewWindow ? (
          <a
            className='button'
            href={button.link}
            target='_blank'
            rel='noopener noreferrer'
          >
            {button.labelText}
          </a>
        ) : (
          <Link className='button' to={button.link}>
            {button.labelText}
          </Link>
        )}
      </div>
    </div>
  );
}
