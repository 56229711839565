import React from 'react';
//
import { StateProvider } from '../Store';
import Head from './../components/head/Head';
import TopNav from './../components/topNav/TopNav';
import Footer from './../components/footer/Footer';

import Hero from './../components/hero/Hero';
import TextBlock from './../components/textBlock/TextBlock';
import Tout from './../components/tout/Tout';
import FAQ from './../components/faq/FAQ';
import ArticleBlock from './../components/articleBlock/ArticleBlock';
import ArticleQuoteBlock from './../components/articleQuoteBlock/ArticleQuoteBlock';
import TextLinkList from './../components/textLinkList/TextLinkList';
import CompanyList from './../components/companyList/CompanyList';
import SocialMediaCarousel from './../components/socialMediaCarousel/SocialMediaCarousel';
import Video from './../components/video/Video';
import ThemeWrapper from './ThemeWrapper';
import StoryBlock from './../components/storyBlock/StoryBlock';
import YoutubeOverlay from './../components/youtubeOverlay/YoutubeOverlay';
import EmailForm from './../components/emailForm/EmailForm';
import AlumniBlock from '../components/alumniBody/AlumniBlock';

export default function SubPage({
  pageContext: { topNavData, footerData, bodyComponents, seoMetadata },
}) {
  const renderBodyComponents = () => {
    const components = [];

    bodyComponents.forEach((componentData, i) => {
      switch (componentData.sys.contentType.sys.id) {
        case 'componentHero':
          components.push(<Hero key={i} {...componentData} />);

          break;

        case 'componentTextBlock':
          components.push(<TextBlock key={i} {...componentData} />);

          break;

        case 'componentTout':
          components.push(<Tout key={i} {...componentData} />);

          break;

        case 'componentFAQ':
          components.push(<FAQ key={i} {...componentData} />);

          break;

        case 'componentArticleBlock':
          components.push(<ArticleBlock key={i} {...componentData} />);

          break;

        case 'componentArticleQuoteBlock':
          components.push(<ArticleQuoteBlock key={i} {...componentData} />);

          break;

        case 'componentTextLinkList':
          components.push(<TextLinkList key={i} {...componentData} />);

          break;

        case 'componentCompanyList':
          components.push(<CompanyList key={i} {...componentData} />);

          break;

        case 'componentSocialMediaCarousel':
          components.push(<SocialMediaCarousel key={i} {...componentData} />);

          break;

        case 'componentVideo':
          components.push(<Video key={i} {...componentData} />);

          break;

        case 'componentStoryBlock':
          components.push(<StoryBlock key={i} {...componentData} />);

          break;

        case 'componentEmailForm':
          components.push(<EmailForm key={i} {...componentData} />);

          break;

        case 'pageStories':
          // components.push(<AlumniBlock key={i} {...componentData} />);
          components.push(<AlumniBlock key={i} {...componentData} />);

          break;

        default:
          break;
      }
    });

    return components;
  };

  return (
    <div className='SubPage'>
      <StateProvider>
        <Head {...seoMetadata} />
        <ThemeWrapper pageStyle='sub'>
          <TopNav topNavData={topNavData} />
          <main>{renderBodyComponents()}</main>
          <Footer footerData={footerData} />
        </ThemeWrapper>
        <YoutubeOverlay />
      </StateProvider>
    </div>
  );
}
