import React from 'react';
import _ from 'lodash';
//
import './TextLinkList.scss';

export default function TextLinkList({ fields }) {
  const parseFields = () => {
    return {
      textLinks: _.get(fields, 'textLinks', []),
    };
  };

  const { textLinks } = parseFields();

  const renderTextLinks = () => {
    return textLinks.map((item, index) => {
      const { link, labelText, opensNewWindow } = item.fields;

      return (
        <a
          key={index}
          className='text-link text-Small'
          href={link}
          target={opensNewWindow ? '_blank' : '_self'}
          rel={opensNewWindow ? 'noopener noreferrer' : ''}
        >
          {labelText}
        </a>
      );
    });
  };

  return (
    <div className='TextLinkList'>
      <div className='text-link-list-container'>{renderTextLinks()}</div>
    </div>
  );
}
