import React from 'react';
import _ from 'lodash';
import Markdown from 'react-markdown';
//
import './Hero.scss';

export default function Hero({ fields }) {
  const parseFields = () => {
    return {
      headerText: _.get(fields, 'headerText', 'HEADER TEXT MISSING'),
      secondaryText: _.get(fields, 'secondaryText', null),
      mobileImage: _.get(
        fields,
        'mobileImage.fields.image.fields.file.url',
        '/img/PLACEHOLDER.jpg'
      ),
      desktopImage: _.get(
        fields,
        'desktopImage.fields.image.fields.file.url',
        '/img/PLACEHOLDER.jpg'
      ),
      altText: _.get(fields, 'mobileImage.fields.altText', 'ALT TEXT MISSING'),
    };
  };

  const { headerText, secondaryText, mobileImage, desktopImage, altText } =
    parseFields();

  return (
    <div className='Hero'>
      <div className={`hero-image`}>
        <picture>
          <source
            media='(max-width: 767px)'
            srcSet={`${mobileImage}`}
            sizes='100vw'
          />
          <img src={desktopImage} alt={altText} />
        </picture>
      </div>
      <div className='hero-copy'>
        <h1 className={`primary text-XLarge`}>
          <Markdown children={headerText} />
        </h1>
        {secondaryText ? (
          <Markdown
            className='secondary text-Medium'
            children={secondaryText}
          />
        ) : null}
      </div>
    </div>
  );
}
