import React, { useState } from 'react';
import _ from 'lodash';
import Markdown from 'react-markdown';
//
import './EmailForm.scss';

export default function EmailForm({ fields }) {
  const parseFields = () => {
    return {
      headerText: _.get(
        fields,
        'headerText.fields.text',
        'HEADER TEXT MISSING'
      ),
      headerTextSize: _.get(fields, 'headerText.fields.textSize', 'Medium'),
      subheadText: _.get(
        fields,
        'subheadText.fields.text',
        'SUBHEAD TEXT MISSING'
      ),
      subheadTextSize: _.get(fields, 'subheadText.fields.textSize', 'Medium'),
      imageMobile: _.get(
        fields,
        'imageMobile.fields.image.fields.file.url',
        '/img/PLACEHOLDER.jpg'
      ),
      imageDesktop: _.get(
        fields,
        'imageDesktop.fields.image.fields.file.url',
        '/img/PLACEHOLDER.jpg'
      ),
      altText: _.get(fields, 'imageDesktop.fields.altText', 'ALT TEXT MISSING'),
      formFields: _.get(fields, 'formFields', []),
      button: _.get(fields, 'button.fields', 'BUTTON MISSING'),
    };
  };

  const {
    headerText,
    headerTextSize,
    subheadText,
    subheadTextSize,
    imageMobile,
    imageDesktop,
    altText,
    formFields,
    button,
  } = parseFields();

  const initFormFieldsState = () => {
    const state = {};

    formFields.forEach(field => {
      state[field.fields.fieldID] = {
        isValid: false,
        value: '',
      };
    });

    return state;
  };

  const [formFieldsState, setFormFieldsState] = useState(initFormFieldsState());
  const [hasValidated, setHasValidated] = useState(false);

  const handleChangeFormField = (e, fieldID) => {
    setFormFieldsState({
      ...formFieldsState,
      [fieldID]: {
        value: e.target.value,
        isValid: e.target.value.length > 0,
      },
    });
  };

  const handleClickSubmit = () => {
    setHasValidated(true);

    let numValid = 0;

    Object.keys(formFieldsState).forEach(fieldID => {
      if (formFieldsState[fieldID].isValid) numValid++;
    });

    if (numValid === formFields.length) {
      let body = '';

      formFields.forEach((field, i) => {
        const { labelText, fieldID } = field.fields;
        body += `${labelText}: %0D%0A${formFieldsState[fieldID].value}`;

        if (i !== formFields.length - 1) body += '%0D%0A%0D%0A';
      });

      window.open(
        `${button.link}?subject=I am interested in partnering with Adfellows&body=${body}`,
        '_blank'
      );
    }
  };

  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      handleClickSubmit();
    }
  };

  const renderFormFields = () => {
    return formFields.map(field => {
      const { fieldID, labelText } = field.fields;
      const { value, isValid } = formFieldsState[fieldID];

      return (
        <div className='form-field' key={fieldID}>
          <label htmlFor={fieldID}>{labelText}</label>
          <input
            className={`${hasValidated && !isValid ? 'invalid' : ''}`}
            type='text'
            id={fieldID}
            name={labelText}
            maxLength={300}
            value={value}
            onChange={e => handleChangeFormField(e, fieldID)}
          />
        </div>
      );
    });
  };

  return (
    <div className='EmailForm'>
      <div className='text-wrapper'>
        <div className='image-wrapper'>
          <picture>
            <source
              media='(max-width: 767px)'
              srcSet={`${imageMobile}`}
              sizes='100vw'
            />
            <img src={imageDesktop} alt={altText} />
          </picture>
        </div>
        <div className='header-wrapper'>
          <div className={`header-text text-${headerTextSize}`}>
            <Markdown children={headerText} />
          </div>
          <div className={`subhead-text text-${subheadTextSize}`}>
            <Markdown children={subheadText} />
          </div>
        </div>
      </div>
      <div className='form-fields'>{renderFormFields()}</div>
      <div
        className={`button`}
        onClick={handleClickSubmit}
        onKeyDown={e => handleKeyDown(e)}
        role='button'
        tabIndex={0}
      >
        {button.labelText}
      </div>
    </div>
  );
}
