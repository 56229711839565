import React, { useState, useRef } from 'react';
import _ from 'lodash';
import Markdown from 'react-markdown';
//
import './Video.scss';
import YoutubePlayer from './../youtubePlayer/YoutubePlayer';

export default function Video({ fields }) {
  const [videoDisplay, setVideoDisplay] = useState(false);
  const [videoShow, setVideoShow] = useState(false);

  const youtubePlayerRef = useRef();

  const handleClickPlay = () => {
    youtubePlayerRef.current.resetVideo();

    setVideoDisplay(true);

    setTimeout(() => {
      setVideoShow(true);

      setTimeout(() => {
        youtubePlayerRef.current.playVideo();
      }, 500);
    }, 60);
  };

  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      handleClickPlay();
    }
  };

  const parseFields = () => {
    return {
      headerText: _.get(fields, 'headerText.fields.text', ''),
      headerTextSize: _.get(fields, 'headerText.fields.textSize', 'Medium'),
      quoteText: _.get(fields, 'quoteText.fields.text', ''),
      quoteTextSize: _.get(fields, 'quoteText.fields.textSize', 'Medium'),
      videoID: _.get(fields, 'youtubeVideo.fields.videoID', 'VIDEO ID MISSING'),
      mobilePreviewImage: _.get(
        fields,
        'mobilePreviewImage.fields.image.fields.file.url',
        '/img/PLACEHOLDER.jpg'
      ),
      desktopPreviewImage: _.get(
        fields,
        'desktopPreviewImage.fields.image.fields.file.url',
        '/img/PLACEHOLDER.jpg'
      ),
      desktopPreviewImageALtText: _.get(
        fields,
        'desktopPreviewImage.fields.altText',
        'DESKTOP PREVIEW IMAGE ALT TEXT MISSING'
      ),
    };
  };

  const {
    headerText,
    headerTextSize,
    quoteText,
    quoteTextSize,
    videoID,
    mobilePreviewImage,
    desktopPreviewImage,
    desktopPreviewImageALtText,
  } = parseFields();

  return (
    <div className='Video'>
      {headerText && (
        <div className={`header-text text-${headerTextSize}`}>
          <Markdown children={headerText} />
        </div>
      )}
      <div className='video-container'>
        <div
          className={`video-thumbnail ${videoDisplay ? 'hide' : ''} ${
            videoShow ? 'fade' : ''
          }`}
          role='button'
          tabIndex='0'
          onKeyDown={e => handleKeyDown(e)}
          onClick={handleClickPlay}
        >
          <picture>
            <source
              media='(max-width: 767px)'
              srcSet={`${mobilePreviewImage}`}
              sizes='100vw'
            />
            <img src={desktopPreviewImage} alt={desktopPreviewImageALtText} />
          </picture>
          <div className='play-button'>
            <img src='/img/play.svg' alt='Play' />
          </div>
        </div>
        <div
          className={`video-player ${videoDisplay ? 'display' : ''} ${
            videoShow ? 'show' : ''
          }`}
        >
          <YoutubePlayer ref={youtubePlayerRef} videoID={videoID} />
        </div>
      </div>
      {quoteText && (
        <div className='quote-text-container'>
          <div className='quotation-marks'>“”</div>
          <div className={`quote-text text-${quoteTextSize}`}>{quoteText}</div>
        </div>
      )}
    </div>
  );
}
